<template>
  <form @submit.prevent="saveAssignment" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Assigned To
        <span class="text-red-500">*</span>
      </label>
      <div
        class="fixed inset-0 overflow-hidden z-10"
        v-if="assignmentSelectOpen"
        @click="assignmentSelectOpen = false"
      ></div>
      <div class="relative mx-3">
        <div class="relative -mx-3">
          <input
            readonly
            @click="openSearchAssignment"
            v-model="assignmentName"
            class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
            type="text"
          />
          <button
            v-if="assignmentName"
            name="Clear Supplier"
            @click="clearAssignment"
            class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div
          v-if="assignmentSelectOpen"
          class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
        >
          <div class="relative flex flex-col">
            <input
              ref="tbStaffSearch"
              type="text"
              v-model="assignmentSearchTerm"
              @keydown="searchAssignment"
              class="bge-input bge-input-spacing rounded"
              placeholder="Assignment name (enter 3 letters or more to search)"
            />
            <div class="flex flex-col overflow-y-scroll bg-white mt-2">
              <template v-for="result in assignmentResults">
                <button
                  type="button"
                  :key="result.staff_id"
                  @click="selectAssignment(result)"
                  class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                >
                  <span class="ml-1">
                    {{ result.first_name }} {{ result.last_name }}
                    <!-- <span v-if="result.first_name">({{ result.first_name }})</span> -->
                  </span>
                </button>
              </template>
              <p class="mx-auto py-4 px-8" v-if="assignmentResults == ''">
                No assignments match your search
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Start Date <span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="assignment.requisition_startdate"
        format="dd/MM/yyyy"
        @selected="startDateSelected()"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >End Date<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="assignment.requisition_enddate"
        :open-date="openEndDate"
        :disabledDates="disabledEndDates"
        format="dd/MM/yyyy"
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Purpose</label>
      <input
        v-model="assignment.purpose"
        type="text"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Purpose..."
      />
    </div>

    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="saveAssignment"
          class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save Assignment</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditAssignment",
  components: {
    Datepicker,
    IsAuthorized,
  },
  props: {
    assignment: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      // isBusy: false,
      // serviceTicket: {},
      assignmentName: "",
      assignmentSearchTerm: "",
      assignmentResults: [],
      assignmentSelectOpen: false,
      openEndDate: new Date(),
      disabledEndDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  mounted() {
    if (this.assignment) {
      this.selectAssignmentOnInit(this.assignment.staff_name);

      if (this.assignment.requisition_startdate) {
        this.openEndDate = new Date(this.assignment.requisition_startdate);
        this.disabledEndDates.to = new Date(
          this.assignment.requisition_startdate,
        );
      }
    }
  },
  methods: {
    startDateSelected(e) {
      this.$nextTick(() => {
        const startDate = new Date(this.assignment.requisition_startdate);

        this.openEndDate = new Date(startDate);
        this.disabledEndDates.to = new Date(startDate);

        const endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear() + 5);

        this.assignment.requisition_enddate = endDate;
      });
    },
    openSearchAssignment: function () {
      this.assignmentSearchTerm = "";
      this.assignmentResults = [];
      this.assignmentSelectOpen = true;
      this.$nextTick(function () {
        this.$refs.tbStaffSearch.focus();
      });
    },
    searchAssignment: _.debounce(async function () {
      this.assignmentResults = [];
      if (
        this.assignmentSearchTerm != "" &&
        this.assignmentSearchTerm.length >= 3
      ) {
        let results = await this.StaffService.searchStaff(
          this.assignmentSearchTerm,
        );
        this.assignmentResults = results.data;
        console.log(this.assignmentResults);
      }
    }, 500),
    selectAssignment: function (staff) {
      this.assignment.staff_id = staff.staff_id;
      this.assignment.staff_name = staff.first_name + " " + staff.last_name;
      this.assignmentName = staff.first_name + " " + staff.last_name;
      this.assignmentSelectOpen = false;
    },
    selectAssignmentOnInit: function (staffName) {
      this.assignmentName = staffName;
      this.assignmentSelectOpen = false;
    },
    clearAssignment: function () {
      this.assignment.staff_id = "";
      this.assignment.staff_name = "";
      this.assignmentName = "";
      this.$forceUpdate();
    },
    saveAssignment: function () {
      this.$emit("complete", this.assignment);
    },
    deleteAssignment: function () {
      this.$emit("delete", this.assignment);
    },
  },
};
</script>
